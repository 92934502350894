import React, {useState} from 'react'
import { Select, Button, Tag } from 'antd'
import { colorOptions, tagColorOptions } from './config';

const { Option } = Select;
export default ( props: any) => {
  const { textType } = props;
  const colors = textType == 'labels' ? tagColorOptions : colorOptions;
  return (
    <Select
      style={{ width: '100%' }}
      placeholder="请选择"
      {...props}
    >
      {
        colors.map(item => {
          return <Option key={item.value} value={item.value}><Tag color={item.value}>{item.label}</Tag></Option>
        })
      }

    </Select>
  )
}