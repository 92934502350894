import React, { useEffect } from "react";
import { Space, Button } from "antd";
import { useDesigner } from "@designable/react";
import { GlobalRegistry } from "@designable/core";
import { observer } from "@formily/react";
import {
  EyeOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useSchemaService } from "../service";
//@ts-ignore
import openWindow from "@/script/openWindow.mjs";
import { useState } from "react";

interface IActionTypes {
  id: string;
  uvType?: string;
  handleShowSetting: (show: boolean) => void;
  isFullScreen: boolean;
  handleFullScreen: () => void;
  handlePrjData?: (param: any) => void;
  uvMode?: number;
}

export const ActionsWidget: React.FC<IActionTypes> = observer((props) => {
  const { uvMode } = props;
  const designer = useDesigner();
  const schemaService = useSchemaService(uvMode);

  useEffect(() => {
    let isMounted = true;
    schemaService
      .loadSchema(designer, props.id)
      .then((res) => {
        const prjData = {
          sVer: res.sVer,
          prjId: res.prjId,
          uiUserCode: res.uiUserCode,
          uiName: res.uiName,
          pageType: res.pageType,
        };
        props.handlePrjData(prjData);
        //@ts-ignore
        designer.prjData = {
          sVer: res.sVer,
          prjId: res.prjId,
        };
        if (isMounted) {
          props.handleShowSetting(true);
        }
      })
      .catch((err) => {
        props.handleShowSetting(true);
      });

    return () => {
      isMounted = false;
    };
  }, []);
  const supportLocales = ["zh-cn", "en-us", "ko-kr"];
  useEffect(() => {
    if (!supportLocales.includes(GlobalRegistry.getDesignerLanguage())) {
      GlobalRegistry.setDesignerLanguage("zh-cn");
    }
  }, []);

  const handlePreview = () => {
    openWindow("/formilyPreview", { uvId: props.id, uvMode });
    // schemaService.saveSchema(designer, props.id, props.uvType).then(() => {
    //   openWindow("/formilyPreview", { uvId: props.id, uvMode });
    // });
  };

  return (
    <Space style={{ margin: 4 }}>
      <Button
        onClick={props.handleFullScreen}
        icon={
          //@ts-ignore
          !props.isFullScreen ? ( <FullscreenOutlined /> ) : (<FullscreenExitOutlined />)
        }
        type="primary"
        size="small"
        ghost
      >
        {!props.isFullScreen ? "全屏" : "取消全屏"}
      </Button>
      <Button
        type="primary"
        size="small"
        ghost
        //@ts-ignore
        icon={<SaveOutlined />}
        onClick={() => {
          schemaService.saveSchema(designer, props.id, props.uvType);
        }}
      >
        保存
      </Button>
      <Button
        type="primary"
        size="small"
        ghost
        onClick={handlePreview}
        //@ts-ignore
        icon={<EyeOutlined />}
      >
        预览
      </Button>
    </Space>
  );
});
