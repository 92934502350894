import React, { useEffect, useMemo, useState } from 'react'
import { clone } from '@formily/shared'
import { createForm } from '@formily/core'
import { requestIdle } from '@designable/shared'
import { CheckCircleOutlined, SearchOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, Tag, Form, Input, Select, Space } from 'antd'
//@ts-ignore
import Modal from "@/components/Modal/Modal";
//@ts-ignore
import Table from "@/biz/Table";
// import Table from '@/components/Table/Table';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import { createSchemaField, useExpressionScope } from '@formily/react'
//@ts-ignore
import useHTTP from '@/hooks/useHTTP.mjs'
import cls from 'classnames'
import './styles.less'
import { render } from 'react-dom'
import { message } from 'antd'
interface IClickEventSetterProps {
  value?: { id: string}
  onChange?: (value: { id?: string}) => void
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const columns = [
  {
    title: "功能域",
    dataIndex: "fmNamePath",
    ellipsis: true,
    filter: <Table.FilterText/>,
  },

  {
    title: "子系统",
    dataIndex: "ssName",
    ellipsis: true,
    filter: <Table.FilterText/>,
  },

  {
    title: "界面名称",
    dataIndex: "uiName",
    ellipsis: true,
    filter: <Table.FilterText/>,
  },

  {
    title: "界面代码",
    dataIndex: "uiUserCode",
    ellipsis: true,
    filter: <Table.FilterText/>,
  },

  {
    title: "能力标题",
    dataIndex: "algTitle",
    ellipsis: true,
    filter: <Table.FilterText/>,
  },

  {
    title: "能力代码",
    dataIndex: "algUserCode",
    ellipsis: true,
    filter: <Table.FilterText/>,
  },

  {
    title: "能力类型",
    dataIndex: "algType",
    ellipsis: true,
    filter: <Table.FilterText getText={(text) => {
      return text == 'UE' ? '事件' : '基础'
    }}/>,
    render: (text) => (
      <>
        <Tag>
          {text == 'UE' ? '事件' : ''}
          {text == 'ALG' ? '基础' : ''}
        </Tag>
      </>
    ),
  },
];


export const ClickCustomEventSetter: React.FC<IClickEventSetterProps> = (props) => {
  const http = useHTTP();
  const [tabeleData, setTableData] = useState();
  const [loading, setLoading] = useState(false);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 100,
    },
  });
  const $scope = useExpressionScope();
  const [modalVisible, setModalVisible] = useState(false)
  const [innerVisible, setInnerVisible] = useState(false)
  const copyVal = clone(props.value);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  
  const initval = {
    uiUserCode: $scope.prjData.uiUserCode,
    uiName: $scope.prjData.uiName
  }
  
  useEffect(() => {
    if (modalVisible) {
      setSelectedRowKeys(copyVal?.id ? [copyVal.id] : [])
      setSelectedRow(copyVal || {})
      requestIdle(
        () => {
          setInnerVisible(true)
        },
        {
          timeout: 100,
        }
      )
      fetchData(initval);
    } else {
      setInnerVisible(false)
    }
  }, [modalVisible])
  const form = useMemo(() => {
    return createForm({
      values: {
        ...copyVal
      },
    })
  }, [modalVisible, props.value])
  const openModal = () => setModalVisible(true)
  const closeModal = () => setModalVisible(false)
  const handleRowClick = (record) => {
    setSelectedRowKeys([record.pkid]);
    setSelectedRow({id: record.pkid, type: record.algType})
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys, selectedRows) => {
      setSelectedRowKeys(keys);
      const record = selectedRows[0]
      setSelectedRow({id: record.pkid, type: record.algType})
    },
  };

  const fetchData = (query?: any) => {
    setLoading(true);
    http.get('/umuis/ueAndAlgs', {
      stsCodes: 'RLS,REVISE',
      sortMode: 1,
      algType: 'UE',
      ...query,
      ...$scope.prjData
    }).then((data) => {
        setTableData(data)
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: 200,
          },
        });
      }).catch(err => {
        setLoading(false);
      });
  };

  const onFinish = (values: any) => {
    fetchData(values);
  };

  return (
    <>
    <div style={{ width: '100%' , display: 'flex'}}>
        <Button
          //@ts-ignore
          className={cls({
            "dn-btn-suc": copyVal?.id,
          })}
          style={{ flex: 1}}
          onClick={openModal}
          disabled={!!$scope.isTemplate}
        >
          {$scope.isTemplate ? "模板禁用" : copyVal ? "重新配置" : "配置事件能力"}
        </Button>

        {copyVal && (
          <Button
            style={{ width: '32px', borderLeft: 'none', color: '#FF7875'}}
            //@ts-ignore
            onClick={() => props.onChange(undefined)}
            //@ts-ignore
            icon={<CloseCircleOutlined />}
          ></Button>
        )}
      </div>
      <Modal
        title="配置事件能力"
        width={1280}
        style={{ top: 20 }}
        bodyStyle={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
          maxHeight: "calc(80vh - 100px)",
          minHeight: 320,
          background: "#fff",
          padding: "16px 24px",
        }}
        centered
        transitionName=""
        maskTransitionName=""
        open={modalVisible}
        onCancel={closeModal}
        destroyOnClose
        onOk={() => {
          if (selectedRowKeys && selectedRowKeys.length > 0) {
            props.onChange(selectedRow);
            closeModal();
          } else {
            message.warning("请先进行选择");
          }
        }}
      >
        {innerVisible && (
          <>
            <Form
              name="queryForm"
              // labelCol={{ span: 8 }}
              // wrapperCol={{ span: 16 }}
              layout="vertical"
              initialValues={initval}
              onFinish={onFinish}
              autoComplete="off"
              size="small"
            >
              <Space>
                <Form.Item label="界面名称" name="uiName">
                  <Input disabled />
                </Form.Item>

                <Form.Item label="界面代码" name="uiUserCode">
                  <Input disabled />
                </Form.Item>
                <Form.Item label="能力名称" name="algTitle">
                  <Input allowClear />
                </Form.Item>
                <Form.Item label="能力代码" name="algUserCode">
                  <Input allowClear />
                </Form.Item>
                {/* <Form.Item label="能力类型" name="algType">
                <Select allowClear style={{ width: '160px'}} options={[
                    {
                      label: '基础',
                      value: 'ALG'
                    },
                    {
                      label: '事件',
                      value: 'UE'
                    }
                  ]}
                >
                </Select>
              </Form.Item> */}

                <Form.Item
                  style={{ width: "120px" }}
                  label=" "
                  wrapperCol={{ offset: 8 }}
                >
                  <Button
                    //@ts-ignore
                    icon={<SearchOutlined />}
                    type="primary"
                    htmlType="submit"
                  >
                    搜索
                  </Button>
                </Form.Item>
              </Space>
            </Form>

            <Table
              loading={loading}
              rowSelection={{
                type: "radio",
                ...rowSelection,
              }}
              rowKey={'pkid'}
              columns={columns}
              dataSource={tabeleData}
              onRow={(record) => ({
                onClick: () => handleRowClick(record), // 点击行选中
              })}
            />
          </>
        )}
      </Modal>
    </>
  );
}
