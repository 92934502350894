import React, {useState} from 'react'
import { Select, Button, Tag } from 'antd'
import { colorOptions, tagColorOptions } from './config';

const { Option } = Select;
export default ( props: any) => {
  const { dataSource, value, onChange, ...otherProps } = props;

  return (
    <Select
      style={{ width: '100%' }}
      placeholder="请选择"
      mode='multiple'
      value={value?.split(',')}
      onChange={(values) => {
        const stringValue = values.join(',');
        onChange(stringValue)
      }}
      {...otherProps}
    >
      {
        dataSource.map((item, index) => {
          return <Option key={index} value={item.text}><Tag color={item.color}>{item.text}</Tag></Option>
        })
      }

    </Select>
  )
}